import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Link, graphql, StaticQuery } from 'gatsby'

class TagList extends React.Component {
  render() {
    const { data } = this.props
    const { edges: tags } = data.allMarkdownRemark
    const { group } = data.allMarkdownRemark
    return (
      <ul className="p-tagList__list">
        {group.map(tag => (
          <li
            key={tag.fieldValue}
            className="p-tagList__item c-tagButton"
          >
            <Link
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
              className="p-tagList__link c-tagButton__link"
            >
              {tag.fieldValue}
            </Link>
          </li>
        ))}
      </ul>
    )
  }
}

TagList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default () => (
  <StaticQuery
    query={graphql`
      query TagListQuery {
        allMarkdownRemark{
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
          edges {
            node {
              frontmatter {
                tags 
              }
            }
          }
        }
      }
    `}
  render={(data, count) => <TagList data={data} count={count} />}
  />
)