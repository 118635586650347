import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { kebabCase } from 'lodash'
import PreviewCompatibleImage from './PreviewCompatibleImage'


class FeaturedPostList extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="l-container">
        <div className="p-blogList">
        { 
          posts && posts.map(({ node: post }) => (
              <div className="p-blogList__item" key={post.id}>
                <article className={`p-blogList__container ${post.frontmatter.featuredpost  ? ( 'c-featured') : null }`}>
                  <Link to={post.fields.slug}>
                    {post.frontmatter.featuredimage ? (
                      <figure className="p-blogList__figure">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      </figure>
                    ) : (
                      <figure className="p-blogList__figure--noThumbnail">
                        <img className="p-blogList__img" src="/img/blog/nothumbnail.png" alt="no thumbnail" />
                      </figure>
                    )}
                  </Link>
                  <div className="p-blogList__body">
                    <Link className="p-blogList__title" to={post.fields.slug}>
                        {post.frontmatter.title}
                      {/* <span className="p-blogList__date">
                        {post.frontmatter.date}
                      </span> */}
                    </Link>
                    {post.frontmatter.tags && post.frontmatter.tags.length ? (
                      <ul className="p-blogList__tags">
                        {post.frontmatter.tags.map(tag => (
                          <li className="c-tagButton p-blogList__tag" key={tag + `tag`}>
                            <Link className="c-tagButton__link" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                </article>
              </div>
          ))}
        </div>
      </div>
    )
  }
}

FeaturedPostList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query FeaturedPostListQuery {
        allMarkdownRemark(
          limit: 3
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } , featuredpost: { eq: true } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                tags
                date(formatString: "Y.m.d")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 335, maxHeight: 223, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <FeaturedPostList data={data} count={count} />}
  />
)
