import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import BlogList from '../../components/BlogList'
import Helmet from 'react-helmet'

export const HelmetTemplate = ({
  helmet,
}) => {
  return (
    <div>
      {helmet || ''}
    </div>
  )
}

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <HelmetTemplate
          helmet={
            <Helmet titleTemplate="ブログ | 株式会社アーバ">
              <title>{`ブログ`}</title>
              <meta
                name="description"
                content={`ブログ一覧`}
              />
            </Helmet>
          }
        />
          
        <main className="l-main">
          <div className="l-container">
            <h1 className="c-pageTitle" data-spacingsp="mb7">
              <span className="c-pageTitle__container">
                <span className="c-pageTitle__textJa">ブログ</span>
                <span className="c-pageTitle__textEn">BLOG</span>
              </span>
            </h1>
          </div>
          <div className="l-container--wide">
            <ul className="p-breadCrumbs">
              <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
              <li className="p-breadCrumbs__item isCurrent">ブログ</li>
            </ul>
          </div>
          <BlogList />
        </main>
      </Layout>
    )
  }
}
